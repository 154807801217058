import './App.css';
import Card from './gamecard'

function App() {

  return (
    <div className="App">
      <header className="App-header">
	<div>
	  <h1>GPB Football Scores - December 1, 2023</h1>
	  <h2>Final Scores Turn Yellow - Be careful "Final - OT" will not turn yellow!</h2>
	  <Card />
	</div>
      </header>
    </div>
  );
}

export default App;
