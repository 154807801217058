import { useState, useEffect } from "react"
import { io } from "socket.io-client"

const Game_Card = () => {
  const [socket, setSocket] = useState()
  const [games, setGames] = useState()

  useEffect(() => {
	const s = io("https://internationalracingleague.com")
	setSocket(s)
	//console.log('socket started')
	return () => { s.disconnect() }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
    //console.log('interval looping')

    if (socket == null) {
      return
    }
    socket.once("receive-games", (response) => {
	console.log(response)
	setGames(response)
    })

    socket.emit("get-games")
  }, 5000)

    return () => clearInterval(interval)
  }, [socket])

  const pStyle1 = {backgroundColor: 'yellow', color: 'black'}

  //const unescape = (str) => {
  //  return str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
  //}

  return (
		<div className="cards">
		<div className="delete">
		  { games && games.map((game) => (
		    <div className="card" key={game.matchup.nodeID}>
		      <div className="cardBody">

			{ game.matchup.status === 'Final' ?
			  <p style={pStyle1}>{ game.matchup.status }</p> : <p>{game.matchup.status}</p> }

			<div className="line">
			  <div className="team">{ game.matchup.awayTeam }</div>

			{ game.matchup.awayScore == null ?
			  <div className="score">0</div> : <div className="score">{ game.matchup.awayScore }</div> }
			</div>

			<div className="line">
			  <div className="team">{ game.matchup.homeTeam }</div>

			{ game.matchup.homeScore == null ?
			  <div className="score">0</div> : <div className="score">{ game.matchup.homeScore }</div> }
			</div>

		      </div>
		    </div>
		  ))}
		</div>
		</div>
	 )

}

export default Game_Card;
